import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/modules/nonbmA"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const spaData = import("./data/spadata.json");

export default function spatemplates() {

  let css;
    if(isFirefox()){
      css = `   .nonbma-module--nonbma ul.nonbma-module--bullets {
          padding-left: 150px;
      }`
      ;
    }

    else{
      css = ` #nonbma-module--nonbma ul.nonbma-module--bullets {
              text-align: center!important;

            }
            #nonbma-module--nonbma .nonbma-module--bullets li {
              list-style: none;
            }

      `;
    }


  return(
    <HomepageLayout>
      <Helmet>
        <style type="text/css">{`
        body {
          background-image: url(/assets/template-freshy.jpg);
          background-size: cover;
          background-repeat: no-repeat !important;
          background-position: center center !important;
          max-width: 100%;
          height: auto ;
          min-height: 100%;
          width: 100%;
          padding: 0;
          margin: 0 ;
          }
        button.install {
          display: block;
          color: #fff;
          background-color: #02b73a;
          font-size: 30px;
          font-weight: normal;
          padding: 22px 28px;
          margin: 25px auto 10px;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          transition: .2s ease;
          -webkit-box-shadow: 2px 2px 5px 0px rgb(9 12 31 / 40%);
          -moz-box-shadow: 2px 2px 5px 0px rgba(9,12,31,0.4);
          box-shadow: 2px 2px 5px 0px rgb(9 12 31 / 40%);
          min-width: 350px;
          transition: .2s ease-in-out;
        }
        button.install:hover {
          transform: scale(1.1);
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          width: 70%;
          margin: 0 auto;
        }

        #nonbma-module--nonbma .nonbma-module--install h2 {
          margin: 15px auto 20px;
        }

        img#ctaArrow {
          width: 40px;
          margin-left: 5px;
          box-sizing: border-box;
          padding-bottom: 3px;
          }
        }
      `}
         </style>
         <style type="text/css">
           {css}
         </style>
      <title>Template Search - freshysearch.com</title></Helmet>
      <NonbmA data={spaData}></NonbmA>
    </HomepageLayout>
  )
}
